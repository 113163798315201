<template lang="html">
  <section class="project_activities">

    <v-card flat id="projectActivitiesBlock" class="d-flex flex-column flex-grow-1">
      <section id="filterProjectActivities">
        <v-row>
          <v-col class="pb-0" v-for="(structurePart) in this.currentStructure" :key="structurePart.name"> <!-- hier geht this. ? -->
            <v-select :data-cy="'filterSelect_'+structurePart.displayName" :key="structurePart.name" :label="structurePart.displayName" :items="getNonVanishingFilterOptions(structurePart)"
              @change="(value) => changeSelectFilter(structurePart, value)" clearable dense outlined></v-select>
          </v-col>
          <v-col class="pt-sm-0 pt-md-4" cols="12" md="4"> <!--cols ist hier notwendig weil xs keine styles anwendet, mit sm="12" springts bei <600px wieder in eine zeile-->
            <v-text-field id="descriptionFilter" v-model="descriptionFilterString" label="Nach Beschreibung filtern" dense clearable></v-text-field>
          </v-col>
        </v-row>
      </section>

      <v-expansion-panels v-model="expandedPanels" multiple hover accordion data-cy="activityPanel">
        <DynamicActivityNode v-for="node in activityTree" :key="node.key" :expanded="expanded" :structure="currentStructure" :dataset="node" :presences="presences" :entryUpdateEnabled="entryUpdateEnabled" :projectActivitiesRef="selfReference"></DynamicActivityNode> <!--nur ohne this. möglich ?-->
      </v-expansion-panels>
      <div v-if="noNodesShown" class="d-flex justify-center">
        Keine Daten vorhanden. Bitte überprüfen Sie den ausgewählten Filter.
      </div>
      <div v-else>
        <v-row no-gutters class="pt-6" v-if="this.activities && this.activities.length > 0"   >
          <v-btn id="expandAllButton" @click="expandAll()" color="#c00000" class="mr-3 white--text">
            Alles ausklappen
          </v-btn>
          <v-btn id="collapseAllButton" @click="collapseAll()" color="#c00000" class="mr-3 white--text">
            Alles einklappen
          </v-btn>

          <v-col xl="3" lg="5" md="6" sm="7" col="12" >
            <v-select id="structureSelect" ref="structureSelect" class="select" v-model="selectedStructure"
              :item-text="(i) => i.displayName" :item-value="(i) => i" :menu-props="{maxHeight: 400, top: true, offsetY: true}"
              :items="structureSelectItems" multiple chips deletable-chips dense outlined>
              <template v-slot:prepend-item>
                <v-list>
                  <v-list-item v-for="cur in predefinedItems" :key="cur.text" ripple @click="changeSelectedStructure(cur.structure)">
                    <v-list-item-action :set="active = _.isEqual(selectedStructure, cur.structure)">
                      <v-icon v-show="active" color="blue darken-2">mdi-radiobox-marked</v-icon>
                      <v-icon v-show="!active">mdi-radiobox-blank</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ cur.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
              </template>

              <template v-slot:append-outer>
                <div id="small-buttons-select" :style="{visibility: newStructureApplicable ? 'visible' : 'hidden'}">
                  <v-icon color="green lighten-1" class="pr-1" @click="applyStructureChange()">mdi-check</v-icon>
                  <v-icon color="#C00000" @click="cancelStructureChange()">mdi-close</v-icon>
                </div>
              </template>
            </v-select>
          </v-col>

           <v-col cols="auto"  class="ml-auto">
            <v-expansion-panels >
              <v-expansion-panel class="pa-0 pl-3" >
                <section >
                  <v-expansion-panel-header class="pt-0 pb-0 white" expand-icon="">
                    <section >
                      <v-row no-gutters class="subtitle-1 d-flex flex-row">
                        <v-col >
                          <v-card  class="pa-2 d-flex flex-column" flat color='transparent'>
                            <label  class="grey--text overline">Summe aller Leistungen</label>
                            <label >
                              <div >{{this.accumulatedHours}}</div>
                            </label>
                          </v-card>
                        </v-col>
                      </v-row>
                    </section>
                  </v-expansion-panel-header>
                </section>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </section>
</template>

<script lang="js">
  import DynamicActivityNode from '@components/project/DynamicActivityNode.vue';
  import ActivityFilter from '@/mixins/ActivityFilter.vue';

  import _ from 'lodash';

  export default {
    name: 'ProjectActivities',
    components: { DynamicActivityNode },
    mixins: [ActivityFilter],
    props: ['activities', 'presences', 'type', 'entryUpdateEnabled'],
    data() {
      return {
        descriptionFilterString: '',
        currentStructure: [],
        selectedStructure: [],
        structureSelectItems: [],
        activityTree: [],
        expanded: false,
        expandedPanels: [],
        shouldReloadActivityTree: false,
        selfReference: this,
        projectActivitiesProjectHeaders: [{
            text: 'Projekt',
            value: 'projectName',
            sortable: true
          },
          {
            text: 'Aufgabe',
            value: 'name',
            sortable: false
          },
          {
            text: 'h',
            value: 'sum',
            sortable: false
          },
          {
            text: 'Verrechenbar',
            value: 'billable',
            sortable: false
          }
        ],
        accumulatedHours: ''
      }
    },

    created() {
      const makePredefinedItem = (structure) => {
        return {
          text: structure.map(structurePart => structurePart.displayName).join('/'),
          structure: structure
        }
      }

      if (this.type == 'usermonth') {
        this.currentStructure = [this.structureDefinition.project, this.structureDefinition.task, this.structureDefinition.day];
        this.predefinedItems = [
          makePredefinedItem([this.structureDefinition.project, this.structureDefinition.task, this.structureDefinition.day]),
          makePredefinedItem([this.structureDefinition.project, this.structureDefinition.task])
        ];
      } else if (this.type == 'projectmonth') {
        this.currentStructure = [this.structureDefinition.task, this.structureDefinition.day, this.structureDefinition.user];
        this.predefinedItems = [
          makePredefinedItem([this.structureDefinition.task, this.structureDefinition.day, this.structureDefinition.user]),
          makePredefinedItem([this.structureDefinition.day, this.structureDefinition.task, this.structureDefinition.user]),
          makePredefinedItem([this.structureDefinition.user, this.structureDefinition.task, this.structureDefinition.day])
        ];
      }

      this.selectedStructure = this.currentStructure;
      this.structureSelectItems = this.currentStructure;

      this.activityTree = this.createActivityTree(this.activities, this.currentStructure);
      this.arraySortByName(this.activityTree);
      this.calculateAccumulatedHours();

      this.currentStructure.forEach((structurePart) => {
        structurePart.userFilter.currentOptions = structurePart.userFilter.allOptions;
      });

      this.$root.$on('activityTreeReloadNeeded', this.onActivityTreeReloadNeeded); // CreateEditPresenceActivity
      this.$root.$on('reload', this.onActivityTreeReloadNeeded); // general "reload"
    },

    beforeDestroy() {
      this.$root.$off('activityTreeReloadNeeded', this.onActivityTreeReloadNeeded);
      this.$root.$off('reload', this.onActivityTreeReloadNeeded);
    },

    computed: {
      _: function() {
        return _;
      },

      newStructureApplicable: function () {
        return !(_.isEqual(this.currentStructure, this.selectedStructure) && this.selectedStructure?.length > 0);
      },
      
      noNodesShown: function() {
        return this.activityTree.some((node) => node.show == true) == false;
      }
    },

    methods: {
      onActivityTreeReloadNeeded: function () {
        this.shouldReloadActivityTree = true;
      },

      applyFiltersAndSetFilterOptions() {
        this.applyFiltersToTree(this.activityTree, this.currentStructure, this.descriptionFilterString);
        this.setFilterOptions(this.activities, this.currentStructure, this.descriptionFilterString);
        this.calculateAccumulatedHours();
      },

      expandAll() {
        this.expandedPanels = [...Array(this.activityTree.length).keys()];
        this.$emit('expandAll');
      },

      collapseAll() {
        this.expandedPanels = [];
        this.$emit('collapseAll');
      },

      getNonVanishingFilterOptions(structurePart) {
        const options = [...structurePart.userFilter.currentOptions];
        if (structurePart.userFilter.selected) {
          options.push(structurePart.userFilter.selected);
        }
        return this.arraySort(_.uniq(options));
      },

      changeSelectFilter(structurePart, filterValue) {
        structurePart.userFilter.selected = filterValue;
        this.applyFiltersAndSetFilterOptions();
      },

      changeSelectedStructure(structure) {
        this.selectedStructure = structure;
      },

      applyStructureChange() {
        this.$refs.structureSelect.blur();
        this.expanded = false;
        this.expandedPanels = [];
        this.$root.$emit('activityFilterStructureChange');
        this.selectedStructure.forEach((structurePart) => {
          const structurePartAlreadyIncluded = _.some(this.currentStructure, ['name', structurePart.name]);

          if (!structurePartAlreadyIncluded) {
            structurePart.userFilter.selected = undefined;
          }
        });
        this.currentStructure = this.selectedStructure;
        this.activityTree = this.createActivityTree(this.activities, this.currentStructure);
        this.applyFiltersAndSetFilterOptions();
      },

      cancelStructureChange() {
        this.$refs.structureSelect.blur();
        this.selectedStructure = this.currentStructure;
      },

      calculateAccumulatedHours() {
        let accumulatedSum;
        let accumulatedSumOfDisplayed;

        accumulatedSum = _.sumBy(this.activityTree, 'sum');
        accumulatedSumOfDisplayed = _.sumBy(this.activityTree, 'sumOfDisplayed');

        const roundedAccumulatedSum = this.precisionRound(accumulatedSum);
        const roundedAccumulatedSumOfDisplayed = this.precisionRound(accumulatedSumOfDisplayed);

        if (roundedAccumulatedSumOfDisplayed === undefined || roundedAccumulatedSum === roundedAccumulatedSumOfDisplayed) {
          this.accumulatedHours = `${roundedAccumulatedSum}`;
        } else {
          this.accumulatedHours = `${roundedAccumulatedSumOfDisplayed} (${roundedAccumulatedSum})`
        }
      }
    },

    watch: {
      activities: function() {
        if (this.shouldReloadActivityTree) {
          this.activityTree = this.createActivityTree(this.activities, this.currentStructure);
          this.applyFiltersAndSetFilterOptions();
          //this.expandedPanels = [];
          this.expanded = false;
          this.shouldReloadActivityTree = false;
        }
      },
      descriptionFilterString: function() {
        this.applyFiltersAndSetFilterOptions();
      },
    },
  }
</script>
<style type="scss">
  .v-select__slot label {
    font-size: 14px;
  }

  #small-buttons-select {
    width: 52px;
  }
</style>