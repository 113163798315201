<script lang="js">
import dayjs from '@util/dayjs';

export default {
  data() {
    return {
    }
  },

  methods: {
    makeNumberSigned(number, customZeroPrefix = undefined, reverse = false) {
      if (isNaN(number)) {
        return '-';
      }
      if (number == 0) {
        const zeroPrefix = customZeroPrefix === undefined ? '+/-' : customZeroPrefix;
        return zeroPrefix + number;
      } else {
        let numberPrefix = number > 0 ? '+' : '';
        if (number > 0 && reverse == true) {
          numberPrefix = '-';
        }
        return numberPrefix + number;
      }
    },

    formatTimestamp(timestamp) {
      if (timestamp) {
        return dayjs(timestamp, 'YYYY-MM-DD').isValid() ? `${dayjs(timestamp).format('DD.MM.YYYY')} um ${dayjs(timestamp).format('HH:mm')} Uhr` : 'invalid';
      }
      return timestamp;
    },

    formatDate(date) {
      if (date) {
        return dayjs(date, 'YYYY-MM-DD').isValid() ? dayjs(date).format('DD.MM.YYYY') : 'invalid';
      }
      return date;
    },

    projectDisplayName(item) {
      return item.shortName ?? item.name;
    }
  },
};
</script>