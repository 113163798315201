<template lang="html">
  <section class="month">
    <v-overlay data-cy="loading-overlay" :value="this.showProjectLoadingOverlay" absolute />

    <Flicker :loading="this.loading"
      :bottomText="this.projectData && this.projectData.name ? `${projectDisplayName(this.projectData)} (${this.projectData.identifier})` : ''"
      @changeRoute="this.changeRoute">
    </Flicker>

    <br><br>
    <div v-if="this.showProject">

      <!-- Projekte & Aufgaben -->
      <v-row no-gutters>
        <v-col cols="0" md="1"></v-col>
        <v-col cols="auto" md="10">
          <ProjectActivities :activities="this.projectData.activities" type="projectmonth" :entryUpdateEnabled="false">
          </ProjectActivities>
        </v-col>
        <v-col cols="0" md="1"></v-col>
      </v-row>

      <!-- Anhänge -->
      <v-row class="mt-4">
        <v-col cols="0" md="1"></v-col>
        <v-col cols="auto" md="10">
          <v-expansion-panels>
            <v-expansion-panel id="attachmentsPanel">
              <v-expansion-panel-header>
                <v-row class="align-center justify-center title grey--text" no-gutters>
                  Anhänge ({{ this.attachments ? this.attachments.length : '0' }})
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <FileManager v-if="this.projectData" class="ma-0 pa-0" :attachments="this.attachments" :fileManipulationEnabled="this.fileManipulationEnabled"
                  :fileUploadLoading="this.fileUploadLoading" :fileDownloadLoadingIDs="this.fileDownloadLoadingIDs" :fileDeleteLoadingIDs="this.fileDeleteLoadingIDs"
                  @addAttachments="this.addAttachments" @downloadAttachment="this.downloadAttachment" @deleteAttachment="this.deleteAttachment">
                </FileManager>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="0" md="1"></v-col>
      </v-row>
      <!-- /Anhänge -->

      <!-- Project Zusammenfassung -->
      <v-row no-gutters style="flex-wrap: wrap;">

        <v-card style="flex-basis: 100px;" class="d-flex flex-column pt-2 pb-2 pr-2 mt-2 mb-2 mr-2 flex-grow-1">
          <label class="title pa-2 mb-2 text-center grey--text">Stunden</label>

          <div>
            <div class="d-flex flex-row pl-2 pr-2">
              <label class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
                Verrechenbar
              </label>
              <label class="black--text subtitle-1 justify-end" data-cy="billableHours">
                {{ this.projectData.hours.activitiesBillable }} h
              </label>
            </div>

            <div class="d-flex flex-row pl-2 pr-2">
              <label class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
                Nicht verrechenbar
              </label>
              <label class="black--text subtitle-1 justify-end" data-cy="nonBillableHours">
                {{ this.projectData.hours.activitiesNotBillable }} h
              </label>
            </div>
            <v-divider class="pl-2 pr-2"></v-divider>

            <div class="d-flex flex-row pl-2 pr-2">
              <label class="d-flex flex-grow-1 black--text subtitle-1 justify-start font-weight-bold">
                Gesamt
              </label>
              <div class="black--text subtitle-1 justify-end font-weight-bold">
                <label data-cy="totalBillableHours">
                  {{ this.projectData.hours.activities }} h
                </label>
              </div>
            </div>
          </div>
        </v-card>

        <v-card id="flexTimeBlock" style="flex-basis: 100px;" class="d-flex flex-column pa-2 ma-2 flex-grow-1">
          <label id="flexTimeBlockHeader" class="title pa-2 mb-2 text-center grey--text">Mitarbeiter</label>

          <div>
            <div v-for="member in memberStatuses" :key="member.id" class="d-flex flex-row pl-2 pr-2">
              <label :data-cy="'employeeName_' + member.firstname + ' ' + member.lastname"
                class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
                {{ member.firstname }} {{ member.lastname }} ({{ member.status }})
              </label>
              <label class="d-flex flex-shrink-0 black--text subtitle-1 justify-end" data-cy="totalEmployeeProjectHours">
                {{ member.sum }} h
              </label>
            </div>
          </div>
        </v-card>
      </v-row>
      <!-- /Project Zusammenfassung -->

      <!-- Projektabschluss -->
      <section id="ProjectClosing">
        <v-row no-gutters class="pt-6 pl-12 pr-12">
          <v-col md="4"></v-col>
          <v-col class="d-flex justify-end">
            <v-card class="pa-2 d-flex flex-column text-center" flat color='transparent'>
              <label class="grey--text overline">Verrechnet am</label>
              <label id="timestamp">{{ this.projectSettledDate }}</label>
            </v-card>
            <v-card v-if="this.projectData.closing" class="pa-2 d-flex flex-column text-center" flat color='transparent'>
              <label class="grey--text overline">
                Status
                <v-tooltip color="grey darken-4" right v-if="this.projectData.closing.latestComment">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="grey lighten-1">mdi-message</v-icon>
                  </template>
                  Autor: {{ this.projectData.closing.latestComment.author }}<br />
                  <div id="commentbody" style="white-space: pre-line;">{{ this.projectData.closing.latestComment.body }}
                  </div>
                </v-tooltip>
              </label>
              <label id="status">{{ this.projectData.closing.status }}</label>
            </v-card>
            <v-card class="pt-2 pb-2 pl-2 pb-12" flat color='transparent'>
              <v-btn id="submitProject" color="#c00000" class="mr-2 white--text" @click="submitProject()"
                :disabled="this.submitting" v-if="this.projectSubmitable">Freigabe zur Verrechnung</v-btn>
              <v-btn id="askQuestion" color="#c00000" class="mr-2 white--text" @click="clarifyProjektabschluss()"
                :disabled="this.submitting" v-if="this.projectClarifyable">Rückfrage/Zurückziehen</v-btn>
              <v-btn id="fixProject" color="#c00000" class="mr-2 white--text" @click="resubmitProject()"
                :disabled="this.submitting" v-if="this.projectResubmitable">Erneut freigeben</v-btn>
              <v-btn id="settleDraft" color="#c00000" class="mr-2 white--text" @click="draftProjektabschluss()"
                :disabled="this.submitting" v-if="this.projectSettleDraftable">Verrechnet (Entwurf)</v-btn>
              <v-btn id="settle" color="#c00000" class="mr-2 white--text" @click="settleProjektabschluss()"
                :disabled="this.submitting" v-if="this.projectSettlealbe">Verrechnet</v-btn>
              <v-btn id="cancel" color="#c00000" class="mr-2 white--text" @click="cancelProjektabschluss()"
                :disabled="this.submitting" v-if="this.projectCancelable">Storno</v-btn>
              <v-btn id="gotoJiraTicket" color="#c00000" class="mr-2 white--text" v-if="this.showGotoTicket"
                :href="global.JIRA_SERVER_URL + '/browse/' + this.projectData.closing.jiraKey" target="_blank">Jira
                Ticket</v-btn>
            </v-card>
          </v-col>
        </v-row>
      </section>
    </div>
    <v-row v-else-if="!this.loading" no-gutters class="d-flex justify-center">
      Keine Daten vorhanden
    </v-row>

    <!-- /Projektabschluss -->
    <Confirm ref="confNotFound" confirmButtonText="Weiter" :cancelButtonText="null"></Confirm>

    <v-snackbar id="errorMessage" v-if="error.show" v-model="error.show" color="red accent-2" timeout="5000" top app centered elevation="6">
      {{ error.message }}
    </v-snackbar>
  </section>
</template>

<script lang="js">
import dayjs from '@util/dayjs';
import _ from "lodash"

import FileManager from '@components/FileManager.vue';
import Flicker from '@components/Flicker.vue';
import Confirm from '@components/Confirm.vue';
import ProjectActivities from '@components/user/ProjectActivities.vue'
import ActivityFilter from '@/mixins/ActivityFilter.vue'

import * as sqtClient from '@client/client.sqt';
import * as projektabschlussClient from '@client/client.projektabschluss';


export default {
  name: 'Projektansicht',
  components: {
    ProjectActivities,
    Confirm,
    Flicker,
    FileManager
  },
  mixins: [ActivityFilter],
  props: [],

  created() {
    this.$root.$on('startLoading', this.startLoading);
    this.$root.$on('reload', this.reloadProject);
  },

  mounted() {
    this.initialLoadProject();
  },

  beforeDestroy() {
    this.$root.$off('startLoading', this.startLoading);
    this.$root.$off('reload', this.reloadProject);
  },

  data() {
    return {
      loading: false,
      initialProjectLoad: true,
      submitting: false,
      projectData: {},
      tasksByUser: [],
      attachments: [],
      fileUploadLoading: false,
      fileDownloadLoadingIDs: [],
      fileDeleteLoadingIDs: [],
      error: { 
        show: false,
        message: ''
      }
    }
  },

  computed: {
    /* tasksByUser: function() {
      // Wieso auch immer ist das ein infinite loop
      return this.createChildData(_.cloneDeep(this.projectData.activities), this.structureDefinition.user);
    },*/

    showProject: function () {
      return (this.initialProjectLoad == false) && (this.projectData.activities) && (this.projectData.activities.length > 0);
    },

    showProjectLoadingOverlay: function () {
      return this.loading && this.initialProjectLoad == false;
    },

    displayedDate: function () {
      return this.$store.state.displayedMonth;
    },

    showGotoTicket: function () {
      return this.projectData.closing.jiraKey != undefined;
    },

    projectSubmitable: function () {
      const staticUserData = this.$store.getters.staticUserData;
      return staticUserData.user.id = this.projectData.leader.id &&
        this.projectData.closing.status === global.projectClosing.status.open &&
        this.projectData.activities.length > 0;
    },

    projectResubmitable: function () {
      const staticUserData = this.$store.getters.staticUserData;
      return staticUserData.user.id = this.projectData.leader.id &&
        this.projectData.closing.status === global.projectClosing.status.inClarification &&
        this.projectData.activities.length > 0;
    },

    projectClarifyable: function () {
      return this.projectData.closing.status === global.projectClosing.status.readyToSettle;
    },

    projectSettleDraftable: function () {
      const staticUserData = this.$store.getters.staticUserData;
      return staticUserData.user.roles.isFA && this.projectData.closing.status === global.projectClosing.status.readyToSettle;
    },

    projectSettlealbe: function () {
      const staticUserData = this.$store.getters.staticUserData;
      return staticUserData.user.roles.isFA && this.projectData.closing.status === global.projectClosing.status.draft;
    },

    projectCancelable: function () {
      const staticUserData = this.$store.getters.staticUserData;
      return staticUserData.user.roles.isFA && (
        this.projectData.closing.status === global.projectClosing.status.draft ||
        this.projectData.closing.status === global.projectClosing.status.settled
      );
    },

    projectSettledDate: function () {
      if (this.projectData.closing.status != global.projectClosing.status.settled ||
        this.projectData.closing.updated == undefined) {
        return '-';
      }

      return dayjs(this.projectData.closing.updated).format('DD.MM.YYYY HH:mm:ss');
    },

    structureSelectionChanged: function () {
      return !_.isEqual(this.structure, this.tempStructure);
    },

    fileManipulationEnabled: function () {
      if (this.displayedDate.isAfter(dayjs())) {
        return false;
      }
      
      switch (this.projectData.closing?.status) {
        case global.projectClosing.status.open:
        case global.projectClosing.status.inClarification:
          return true;
        default:
          return false;
      }
    }
  },

  methods: {
    startLoading() {
      this.loading = true;
    },

    loadedProjectID() {
      return this.$route ? this.$route.query.projectID : undefined;
    },

    /// Nur für einfacheres testing...
    async loadProjectData(projectID, month, year) {
      const staticUserData = this.$store.getters.staticUserData;
      const startOfMonth = this.displayedDate.startOf('month').startOf('day');
      const endOfMonth = this.displayedDate.endOf('month').endOf('day');

      // TODO wieso nicht gleich ein "getMonatsdaten" Aquivalent machen? So ist das doch ziemlich unsauber nacher "getMonatsdaten" aufzurufen
      const projectLeadActivities = await sqtClient.getMocoProjectLeadActivities(projectID, startOfMonth, endOfMonth);
      
      const monthData = await sqtClient.getMonatsdaten(
        month, year,
        projectLeadActivities.activities, [],
        [], [], staticUserData.schedules,
        this.$store.getters.enabledRules
      );

      const monthActivities = monthData.weeks.flatMap(week => {
        return week.days;
      }).flatMap(day => {
        return day.activities;
      });

      const projectData = {
        ...monthData,
        ...{
          name: projectLeadActivities.project.name,
          shortName: projectLeadActivities.project.shortName,
          identifier: projectLeadActivities.project.identifier,
          leader: {
            id: projectLeadActivities.project.leader.id,
            firstname: projectLeadActivities.project.leader.firstname,
            lastname: projectLeadActivities.project.leader.lastname,
          },
          closing: {
            jiraKey: undefined,
            status: undefined,
            latestComment: undefined,
            updated: undefined,
          },
          activities: monthActivities
        }
      }
      return projectData;
    },

    async addAttachments(files) {
      this.fileUploadLoading = true;

      const result = await projektabschlussClient.addAttachments(this.displayedDate, this.projectData.name, this.projectData.identifier, files);
      if (result != undefined) {
        await projektabschlussClient.clearCacheSpecificRoute(`?projectIdentifier=${this.projectData.identifier}&month=${this.displayedDate.isoMonth()}&year=${this.displayedDate.year()}`);

        this.$root.$emit('reload');
      } else {
        this.error.show = true;
        this.error.message = 'Fileupload fehlgeschlagen!';
      }

      this.fileUploadLoading = false;
    },
    
    async deleteAttachment(attachment) {
      this.fileDeleteLoadingIDs.push(attachment.id);

      const result = await projektabschlussClient.deleteAttachment(this.displayedDate, this.projectData.identifier, attachment.id);
      if (result != undefined) {
        await projektabschlussClient.clearCacheSpecificRoute(`?projectIdentifier=${this.projectData.identifier}&month=${this.displayedDate.isoMonth()}&year=${this.displayedDate.year()}`);
        
        this.$root.$emit('reload');
      } else {
        this.error.show = true;
        this.error.message = 'Löschen fehlgeschlagen!';
      }
      
      this.fileDeleteLoadingIDs = this.fileDeleteLoadingIDs.filter(id => id != attachment.id);
    },

    async downloadAttachment(attachment) {
      this.fileDownloadLoadingIDs.push(attachment.id);

      const file = await projektabschlussClient.getAttachment(attachment.url);

      if (file) {
        const blob = new File([file], attachment.filename, { type: attachment.type });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = attachment.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        this.error.show = true;
        this.error.message = 'Download fehlgeschlagen!';
      }

      this.fileDownloadLoadingIDs = this.fileDownloadLoadingIDs.filter(id => id != attachment.id);
    },

    async initialLoadProject() {
      this.initialProjectLoad = true;
      await this.loadProject();
    },

    async reloadProject() {
      await this.loadProject();
    },

    async loadProject() {
      this.loading = true;
      const res = await this.$draf(async () => {
        if (this.$store.getters.userAuthenticationData.mocoApiKey == undefined) {
          return;
        }

        let projectData;
        try {
          projectData = await this.loadProjectData(this.loadedProjectID(),
            this.displayedDate.isoMonth(), this.displayedDate.year());
        } catch (err) {
          await this.openConfirmation(this.$refs.confNotFound, 'Projekt nicht gefunden',
            'Das aufgerufene Projekt existiert nicht. Sie werden nun zum aktuellen Monat weitergeleitet.')

          return () => { this.$router.push({ path: global.ROUTES.aktuellermonat.path }); }
        }

        this.tasksByUser = this.getTasksByUser(projectData.activities);
        this.memberStatuses = await sqtClient.getProjectMemberStatuses(this.loadedProjectID(), this.displayedDate.isoMonth(), this.displayedDate.year());
        this.memberStatuses.forEach((member) => {
          const taskUser = this.tasksByUser.find((taskUser) => {
            return taskUser.id === member.id
          });
          member.sum = taskUser?.sum ?? 0;
        });

        const jiraTicket = await projektabschlussClient.getProjektabschluss(undefined, this.displayedDate.isoMonth(),
          this.displayedDate.year(), projectData.identifier);
          this.attachments = jiraTicket?.attachments;
        if (jiraTicket) {
          projectData.closing.jiraKey = jiraTicket.jiraKey;
          projectData.closing.status = jiraTicket.status;
          projectData.closing.latestComment = jiraTicket.latestComment;
          projectData.closing.updated = jiraTicket.updated;
        } else {
          if (projectData.activities.length == 0) {
            projectData.closing.status = 'nicht verrechenbar';
          } else {
            projectData.closing.status = global.projectClosing.status.open;
          }
        }

        this.projectData = projectData;
      });

      this.initialProjectLoad = false;
      this.loading = false;
      if (typeof (res) == 'function') {
        res();
      }
    },

    async openConfirmation(confirm, title, message) {
      this.loading = false;
      return await confirm.open(title, message);
    },

    changeRoute(displayedDate) {
      this.$router.push({
        name: global.ROUTES.projekt.name,
        params: {
          year: displayedDate.year(),
          month: displayedDate.isoMonth()
        },
        query: {
          projectID: this.loadedProjectID()
        }
      });
    },

    async submitProject() {
      this.submitting = true;

      const jiraTicket = await projektabschlussClient.createProjektabschluss(this.displayedDate, this.projectData.name, this.projectData.identifier);

      this.projectData.closing.status = jiraTicket.status;
      this.projectData.closing.jiraKey = jiraTicket.jiraKey;
      this.projectData.closing.updated = jiraTicket.updated;

      this.submitting = false;
      this.$root.$emit('reloadApp');
    },

    clarifyProjektabschluss() {
      this.changeProjectStatus(global.projectClosing.transition.clarify);
    },

    resubmitProject() {
      this.changeProjectStatus(global.projectClosing.transition.resubmit);
    },

    draftProjektabschluss() {
      this.changeProjectStatus(global.projectClosing.transition.draft);
    },

    settleProjektabschluss() {
      this.changeProjectStatus(global.projectClosing.transition.settle);
    },

    cancelProjektabschluss() {
      this.changeProjectStatus(global.projectClosing.transition.cancel);
    },

    async changeProjectStatus(transition) {
      this.submitting = true;

      await projektabschlussClient.transition(this.projectData.closing.jiraKey, transition, this.projectData.identifier, this.displayedDate);
      this.$root.$emit('reloadApp');

      this.submitting = false;
    }
  },

  watch: {
    displayedDate: function () {
      this.initialLoadProject();
    }
  },
}
</script>

<style type="scss">
.v-input {
  font-size: 14px;
}
</style>