<script lang="js">

export default {
  data() {
    return {
    }
  },

  methods: {
    localeCompare: function(e1, e2) {
      return e1.localeCompare(e2, 'de', { sensitivity: 'case' });
    },

    arraySort: function(array) {
      return array.sort((e1, e2) => this.localeCompare(e1, e2));
    },

    arraySortByName: function(array) {
      return this.arraySortBy(array, 'name');
    },

    arraySortBy: function(array, propertyName) {
      return array.sort((e1, e2) => this.localeCompare(e1[propertyName], e2[propertyName]));
    }
  },
};
</script>