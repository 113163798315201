<template>
  <section>
    <v-dialog v-model="visible" max-width="700px" @keydown.esc="close">

      <v-card>
        <v-card-title>
          <span class="headline">{{'Zeiteintrag aktualisieren' + (this.editedActivity.isEditable == false ? " (Aufgabe gesperrt)" : "")}}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  data-cy="cepa-from"
                  ref="setFrom"
                  v-model="editedPresence.from"
                  label="Von"
                  placeholder=" "
                  dense
                  :maxlength="5"
                  :disabled="presenceEditAllowed == false"
                  @keydown="onTimeFieldChange($event)"
                  @input="(value) => focusElementIf(value.replace(/[^0-9]/g, '').length == 4, $refs['setTo'])"
                  @blur="onFromTimeSet($event)"
                  @focus="$event.target.select()"
                  @keydown.enter="save"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  data-cy="cepa-to"
                  ref="setTo"
                  v-model="editedPresence.to"
                  label="Bis"
                  placeholder=" "
                  dense
                  :maxlength="5"
                  :disabled="presenceEditAllowed == false"
                  @keydown="onTimeFieldChange($event)"
                  @input="(value) => focusElementIf(value.replace(/[^0-9]/g, '').length == 4, $refs['selectProject'].$refs.input)"
                  @blur="onToTimeSet($event)"
                  @focus="$event.target.select()"
                  @keydown.enter="save"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  data-cy="cepa-project"
                  ref="selectProject"
                  v-model="editedActivity.project.id"
                  :items="select.projects"
                  :disabled="activityEditAllowed == false"
                  :item-text="(item) => projectDisplayName(item)"
                  item-value="id"
                  label="Projekt"
                  dense
                  @change="(value) => onSelectProject(value, $refs['selectTask'].$refs.input)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  data-cy="cepa-task"
                  ref="selectTask"
                  v-model="editedActivity.task.id"
                  :items="select.tasks"
                  :disabled="activityEditAllowed == false"
                  item-text="name"
                  item-value="id"
                  @change="() => onSelectTask($refs['description'])"
                  label="Aufgabe"
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  data-cy="cepa-description"
                  ref="description"
                  v-model="editedActivity.description"
                  :disabled="activityEditAllowed == false"
                  label="Beschreibung"
                  autofocus
                  dense
                  @keydown.enter="save"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="#c00000" class="white--text" @click="deleteEntry()" :disabled="saving || (activityEditAllowed == false && presenceEditAllowed == false)" data-cy="cepa-delete">
            Löschen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="#c00000" class="white--text" @click="close()" :disabled="saving" data-cy="cepa-cancel">
            Abbrechen
          </v-btn>
          <v-btn color="#c00000" class="white--text" @click="save()" :disabled="saving || (activityEditAllowed == false && presenceEditAllowed == false)" data-cy="cepa-save">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    
    </v-dialog>

    <Confirm ref="confError" confirmButtonText="OK" cancelButtonText=""></Confirm>
  </section>
</template>

<script>
  import * as mocoClient from '@client/client.moco';
  import Confirm from '@components/Confirm.vue';

  export default {
    name: 'CreateEditPresenceActivity',
    components: {
      Confirm
    },
    props: ['dayData', 'alterOriginalEntries'],
    mounted() {
      this.select.projects = this.arraySortByName([...this.$store.getters.staticUserData.bookableProjects]);
    },

    data() {
      return {
        visible: false,
        presenceEditAllowed: true,
        activityEditAllowed: true,
        saving: false,
        initial: {
          activity: undefined,
          projectID: undefined,
          taskID: undefined
        },
        editedPresence: {},
        editedActivity: {
          project: {},
          task: {},
        },
        select: {
          projects: [],
          tasks: [],
        },
      }
    },

    methods: {

      onTimeFieldChange(event) {
        if (
          /[0-9]/.test(event.key) ||
          event.key === ':' ||
          event.key === 'Backspace' ||
          event.key === 'Tab' ||
          event.metaKey === true
        ) {
          return;
        } else {
          event.preventDefault();
        }
      },

      onFromTimeSet() {
        this.editedPresence.from = this.formatTime(this.editedPresence.from);
      },

      onToTimeSet() {
        this.editedPresence.to = this.formatTime(this.editedPresence.to);
      },

      onSelectProject(projectID, element) {
        this.setTasksForProject(projectID);

        if (element) {
          element.focus();
        }
      },

      onSelectTask(element) {
        if (element) {
          element.focus();
        }
      },

      formatTime(time) {
        let updatedValue = time;
        switch (true) {
          case /^[0-9][0-9]:[0-9][0-9]$/.test(time):
            return time
          case /^[0-9]:[0-9][0-9]$/.test(time):
            return `0${time}`;
          case /^[0-9]{3}$/.test(time):
            updatedValue = `0${time}`;
            //falls through
          case /^[0-9]{4}$/.test(time):
            return `${updatedValue.substring(0, 2)}:${updatedValue.substring(2)}`;
          default:
            return '??:??';
        }
      },
      
      focusElementIf(shouldFocus, element) {
        if (shouldFocus) {
          element.focus();
        }
      },

      editPresence(presence) {
        this.setPresence(presence);

        if (presence.activityId) {
          const matchedActivity = this.dayData.activities.find((activity) => {
            return activity.id == presence.activityId;
          });

          if (matchedActivity) {
            this.setActivity(matchedActivity);
          }
        }

        this.presenceEditAllowed = this.editedPresence.id != undefined && this.editedActivity.isEditable;
        this.activityEditAllowed = this.editedActivity.id != undefined && this.editedActivity.isEditable;
        this.visible = true;
      },

      editActivity(activity, prohibitPresenceEdit) {
        this.setActivity(activity);

        if (activity.presenceId && this.dayData.presences) {
          const matchedPresence = this.dayData.presences.find((presence) => {
            return presence.id == activity.presenceId;
          });

          if (matchedPresence) {
            this.setPresence(matchedPresence);
          }
        }

        if (this.editedPresence.id == undefined) {
          this.editedPresence = {
            from: '-',
            to: '-'
          };
        }

        this.presenceEditAllowed = this.editedPresence.id != undefined && !prohibitPresenceEdit && this.editedActivity.isEditable;
        this.activityEditAllowed = this.editedActivity.id != undefined && this.editedActivity.isEditable;
        this.visible = true;
      },

      setPresence(presence) {
        if (presence == undefined) {
          return;
        }

        this.editedPresence = {
          id: presence.id,
          from: presence.from,
          to: presence.to
        };
      },

      setActivity(activity) {
        if (activity == undefined) {
          return;
        }

        this.editedActivity = {
          id: activity.id,
          description: activity.description,
          isEditable: activity.isEditable,
          project: {
            id: activity.project.id
          },
          task: {
            id: activity.task.id
          },
        };

        this.initial.projectID = activity.project.id;
        this.initial.taskID = activity.task.id;
        this.initial.activity = activity;

        this.setTasksForProject(activity.project.id);
        this.editedActivity.task.id = activity.task.id;
      },

      setTasksForProject(projectID) {
        const selectedProject = this.select.projects.find((project) => project.id == projectID);

        if (selectedProject) {
          // TODO wenn das neue projekt den alten task auch hat, dann den selektieren! bsp: wechsel von razzfaz.io auf yorik
          const filteredTasks = this.editedActivity?.isEditable ? [...selectedProject.tasks].filter((task) => task.active == true || task.active == undefined) : [...selectedProject.tasks];
          this.select.tasks = this.arraySortByName(filteredTasks);
          this.editedActivity.task.id = selectedProject.tasks[0].id;
        } else {
          this.select.tasks = [];
          this.editedActivity.project.id = undefined;
        }
      },

      async save() {
        // TODO - Aufbau diskutieren. Ähnlch wie bei loadMonth muss etwas "davor" und "danach" gemacht werden
        // TODO entweder direkt in der Methode darum kümmern oder eine "helper" drumherum machen

        this.saving = true;
        
        // Zeitformat update (falls das noch nicht passiert ist)
        this.onFromTimeSet();
        this.onToTimeSet();
        
        const updateError = {};
        let updatedPresence;
        if (this.editedPresence.id) {
          try {
            // Sequentiell, damit das Activity Update erst gar nicht gemacht wird, wenn schon das Presence Update fehl schlägt
            updatedPresence = await mocoClient.updatePresence(this.editedPresence.id,this.editedPresence.from, this.editedPresence.to);
          } catch (e) {
            updateError.message = 'Die Presence konnte nicht gespeichert werden.';
            updateError.details = 'Moco Error: ' + Object.entries(e.response?.data)?.map((entry) => entry[1]).join(', ');
          }
        }

        if (this.editedActivity.id && updateError.message == undefined) {
          const presenceDuration = updatedPresence ? this.calcTimeDifferenceAsHours(updatedPresence.from, updatedPresence.to) : undefined;
          try {
            await mocoClient.updateActivity(this.editedActivity.id, this.editedActivity.project.id, this.editedActivity.task.id,
              this.editedActivity.description, presenceDuration);

            if (this.initial.projectID !== this.editedActivity.project.id || this.initial.taskID !== this.editedActivity.task.id) {
              this.$root.$emit('activityTreeReloadNeeded');
            } else if (this.alterOriginalEntries) {
              this.initial.activity.description = this.editedActivity.description;
              this.initial.activity.highlightedDescription = this.editedActivity.description;
            }
          } catch (e) {
            if (this.editedPresence.id) {
              const originalPresence = this.dayData.presences.find((presence) => {
                return presence.id == this.editedPresence.id;
              });

              try {
                updatedPresence = await mocoClient.updatePresence(originalPresence.id, originalPresence.from, originalPresence.to);
                updateError.message = 'Die Activity konnte nicht gespeichert werden.' + (updatedPresence ? ' Die Presence wurde wieder zurückgesetzt.' : '');
              } catch (e) {
                updateError.message = 'Die Activity konnte nicht gespeichert und es gab ein Problem beim Rücksetzen der zugehörigen Presence. Irgendwas ist jetzt wahrscheinlich kaputt :)';
                updateError.details = 'Moco Error: ' + Object.entries(e.response?.data)?.map((entry) => entry[1]).join(', ');
              }
            }
          }
        }

        if (updateError.message) {
          await this.$refs.confError.open('Update Error', updateError.message, updateError.details);
        }

        this.close(true);
      },

      async deleteEntry() {
        this.saving = true;
        
        const deleteError = {};
        if (this.editedPresence.id) {
          try {
            // Sequentiell, damit die Activity erst gar nicht gelöscht wird, wenn schon die Presence nicht gelöscht werden konnte
            await mocoClient.deletePresence(this.editedPresence.id);
          } catch (e) {
            deleteError.message = 'Die Presence konnte nicht gelöscht werden!';
            deleteError.details = 'Moco Error: ' + Object.entries(e.response?.data)?.map((entry) => entry[1]).join(', ');
          }
        }

        if (this.editedActivity.id && deleteError.message == undefined) {
          try {
            await mocoClient.deleteActivity(this.editedActivity.id);
          } catch (e) {
            deleteError.message = 'Die Activity konnte nicht gelöscht werden!';
            deleteError.details = 'Moco Error: ' + Object.entries(e.response?.data)?.map((entry) => entry[1]).join(', ');
          }
        }

        if (deleteError.message) {
          await this.$refs.confError.open('Delete Error', deleteError.message, deleteError.details);
        }

        this.close(true);
      },

      close(reloadApp) {
        this.saving = false;
        if (reloadApp) {
          this.$root.$emit('reloadApp', this);
        }

        this.visible = false;

        this.editedActivity = {
          project: {},
          task: {},
        };
        this.editedPresence = {};
        this.select.tasks = [];
      }
    }
  };
</script>