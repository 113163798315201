import './globals'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/router'
import store from './store/store'
import persistentStore from './store/persistentStore';
import vuetify from './plugins/vuetify';
import TextFormatingMixin from './mixins/TextFormating.vue';
import ArrayMixin from './mixins/Array.vue';
import SQTUtils from './mixins/SQTUtils.vue';

import { statusRules } from 'seqitracker-util';
statusRules.init(global.MOCO_TASK_IDS);

document.title = 'SEQITracker';

Vue.config.productionTip = false;

if (global.isProduction) {
  Vue.config.errorHandler = function (err) {
    store.dispatch('setError', 'Ein unbekannter Fehler ist aufgetreten.\n\n' +
      ` MOCO UserID: ${persistentStore.getAuthentication().mocoUserID}\n` +
      ` MOCO API-Key: ${persistentStore.getAuthentication().mocoApiKey}\n\n ${err.stack}`);
    console.error(JSON.stringify(err));
  }
}

Vue.mixin({
  data: function() {
    return {
      get global() {
        return global;
      }
    }
  },

  methods: {
    $draf: async function(runAfterwards) {
      await new Promise(global.requestAnimationFrame);
      await new Promise(global.requestAnimationFrame);

      if (runAfterwards && typeof runAfterwards === 'function') {
        return await runAfterwards();
      }
    }
  }
});

Vue.mixin(TextFormatingMixin);
Vue.mixin(ArrayMixin);
Vue.mixin(SQTUtils);

const mountVue = () => {
  new Vue(
    {
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
}

if (store.getters.userAuthenticationData.authenticated === false) { //store.state als alternative zu store.getters --> store.getters redundant?
  mountVue();
} else {
  store.dispatch('loadStaticUserData').then(() => {
    mountVue();
  });
}